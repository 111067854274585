
























































































import { DaoDetailSettingViewModel } from '@/modules/community-organize/viewmodels/dao-detail-setting-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'user-item': () =>
      import('@/modules/community-organize/components/dao-detail-setting/components/members/user-item.vue'),
    'black-list-item': () =>
      import('@/modules/community-organize/components/dao-detail-setting/components/members/black-list-item.vue'),
  },
})
export default class extends Vue {
  @Inject({}) setting!: DaoDetailSettingViewModel
  tabs = null

  //
}
